import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RadioButton, Button } from '../../../../components';
import { color, sizing, typography } from '../../../../styles';

const SwatchContainer = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'grid')};
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  grid-gap: 0.5rem;
  /* This is the standardized property now, but has slightly less support */
  /* gap: 1rem */
  margin: 0 1px;
`;

const SwatchBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${sizing.width.full};
`;

const SwatchHeader = styled.header`
  grid-column: 1 / -1;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${sizing.width.full};
  text-transform: capitalize;
  line-height: ${typography.lineHeight[7]};
`;

const TitleValue = styled.span`
  font-size: ${typography.fontSize.sm};
  color: ${color.black};

  font-weight: ${typography.fontWeight.normal};
  margin-left: 0.375rem;
  width: ${sizing.width.full};
`;

const Title = styled.h6`
  font-size: ${typography.fontSize.sm};
  color: ${color.black};
  font-weight: ${typography.fontWeight.medium};
`;

/**
 * Triggers the launching of the size guide by calling a function
 * defined in the shopify theme file
 */
const launch = () => {
  try {
    window['launchSizeGuide']();
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }
};

const OPTIONS = {
  OPTION1: 'option1',
  OPTION2: 'option2',
  OPTION3: 'option3',
};

const Swatch = ({
  register,
  heading,
  headingValue,
  data,
  labels,
  showSizeGuide,
  option,
  previewOnHover,
  showModal,
  hidden,
}) => {
  // use to keep track of what the color is on hover
  // in order to temporarily display the color when
  // a user hovers over one of the non selected colors
  const [titleOnHover, setTitleOnHover] = useState(null);
  const onMouseEnter = (event) => {
    const value = event.target.getAttribute('data-label');
    setTitleOnHover(value);
    if (previewOnHover) {
      previewOnHover(value);
    }
  };
  const onMouseLeave = () => {
    setTitleOnHover(null);
    if (previewOnHover) {
      previewOnHover(null);
    }
  };

  const onClick = (event) => {
    const value = event.target.getAttribute('data-label');
    if (previewOnHover) {
      previewOnHover(value);
    }
  };

  return (
    <SwatchContainer hidden={hidden}>
      <SwatchHeader>
        <Title>
          {heading}{' '}
          <TitleValue>{titleOnHover ? titleOnHover : headingValue}</TitleValue>
        </Title>

        {showSizeGuide && (
          <Button
            appearance="text"
            size="small"
            id="size-guide-launch"
            onClick={() => launch()}
          >
            Size Guide
          </Button>
        )}
      </SwatchHeader>

      <SwatchBody>
        {option === OPTIONS.OPTION1
          ? Array.from(labels).map((key) => (
              <RadioButton
                id={key}
                key={key}
                fill={
                  Object.values(data).filter((val) => val[option] === key)[0]
                    ?.fill
                }
                disabled={
                  Object.values(data).filter((val) => val[option] === key)[0]
                    ? Object.values(data).filter(
                        (val) => val[option] === key
                      )[0].outOfStock
                    : true
                }
                name={option}
                value={key}
                register={register}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
              />
            ))
          : Array.from(labels).map((key) => (
              <RadioButton
                id={key}
                key={key}
                fill={data.filter((datum) => datum[option] === key)[0]?.fill}
                disabled={
                  data.filter((datum) => datum[option] === key)[0]
                    ? data.filter((datum) => datum[option] === key)[0]
                        .outOfStock
                    : true
                }
                name={option}
                value={key}
                register={register}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            ))}
      </SwatchBody>
    </SwatchContainer>
  );
};

Swatch.defaultProps = {
  heading: 'Heading Title',
  showSizeGuide: false,
};

Swatch.propTypes = {
  /** reference to register the inputs with react hook form */
  register: PropTypes.func.isRequired,
  /** the heading of the swatch */
  heading: PropTypes.string.isRequired,
  /**
   * value for the heading of the swatch typically
   * representing which swatch element was selected
   */
  headingValue: PropTypes.string,
  /** Data used to construct the radio buttons values with */
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  /** Data used to construct the radio button labels with */
  labels: PropTypes.instanceOf(Set).isRequired,
  /** Display the size guide button when the swatch is a size swatch */
  showSizeGuide: PropTypes.bool,
  /** Option that represents which swatch to render */
  option: PropTypes.oneOf(Object.values(OPTIONS)),
};

export default Swatch;
