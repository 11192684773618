import styled from 'styled-components';

import { color, typography } from '../styles';

const H3 = styled.h3`
  font-weight: ${typography.fontWeight.thin};
  font-size: ${typography.fontSize['4xl']};
  font-family: ${typography.type.sans};
  color: ${color.black};
`;

export default H3;
