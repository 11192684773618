import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import Pluralize from 'pluralize';

import LineItem from './LineItem';

import {
  sizing,
  color,
  typography,
  media,
  mediaLandscape,
} from '../../../styles';

import { Button } from '../../../components';

import { createCheckout } from '../../product';

import { store } from '../../../store';

import { formatPrice } from '../../../utils';

const CartContainer = styled.div`
  font-size: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: white;
  display: grid;
  grid-template-rows: 5rem 3fr auto;
  border-left: 1px solid #e5e5e5;
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.15s ease-in-out;
  z-index: 100;

  ${media.L`
  max-width: ${sizing.maxWidth.md};
  min-width: ${sizing.maxWidth.md};
  top: 0;
  `};
  ${mediaLandscape.L`
    max-width: ${sizing.maxWidth.md};
    min-width: ${sizing.maxWidth.md};
    top: 0;
  `};
`;

const CartTitle = styled.h2`
  font-size: 1rem;
  margin: 0;
  line-height: 1.75rem;
  font-weight: 500;
`;

const CartCloseButton = styled.button`
  font-size: 1.25rem;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
`;

const CartHeader = styled.header`
  padding: 0 1.2rem;
  border-bottom: 1px solid #e5e5e5;
  display: grid;
  grid-auto-flow: column;
  place-content: center space-between;
  place-self: stretch stretch;
`;
const CartLineItems = styled.ul`
  margin: 0;
  padding: 20px;
  overflow-y: hidden;
  max-height: calc(100vh - 250px);
  &:hover {
    overflow-y: auto;
  }
  ${media.L`
    max-height: calc(100vh - 220px);
  `};
  ${mediaLandscape.L`
    max-height: calc(100vh - 220px);
  `};
`;

const CartFooter = styled.footer`
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  button {
    margin-top: 20px;
  }
`;

const CartInfo = styled.div`
  padding: 15px 20px 10px;
`;

const CartInfoTotal = styled.div`
  float: left;
  font-size: 1rem;
`;

const CartInfoPricing = styled.div`
  float: right;
`;

const CartInfoPricingAmount = styled.span`
  margin-left: 5px;
  font-size: ${typography.fontSize.base};
  color: ${color.black};
`;

const Cart = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state, dispatch } = useContext(store);
  const [initializeCheckout] = useMutation(createCheckout, {
    variables: {
      input: {
        presentmentCurrencyCode: state.currency,
      },
    },
  });
  function initCheckout() {
    let lineItems = [];

    state.checkout.items.forEach((item) => {
      const { id, quantity } = item;
      lineItems.push({ variantId: id, quantity: parseInt(quantity, 10) });
    });

    if (state.dirty) {
      initializeCheckout({
        variables: {
          input: {
            presentmentCurrencyCode: state.currency,
            lineItems,
          },
        },
      })
        .then((res) => {
          setIsSubmitting(false);
          //setCheckout(res.data.checkoutCreate.checkout);
          const { id, webUrl } = res.data.checkoutCreate.checkout;
          dispatch({ type: 'CHECKOUT', id, webUrl });
          window.location.assign(webUrl);
        })
        .catch((error) => console.error(error));
    } else {
      setIsSubmitting(false);
      window.location.assign(state.checkout.webUrl);
    }
  }

  function openCheckout() {
    setIsSubmitting(true);
    initCheckout();
  }
  const line_items = state.checkout.items.map((line_item) => {
    return (
      <LineItem
        key={line_item.id.toString()}
        line_item={line_item}
        currency={state.currency}
      />
    );
  });
  return (
    <CartContainer open={state.isCartOpen}>
      <CartHeader>
        <CartTitle element="h5">
          Cart{' '}
          {line_items.length > 0
            ? `( ${Pluralize('item', line_items.length, true)} )`
            : ''}
        </CartTitle>
        <CartCloseButton onClick={() => dispatch({ type: 'CLOSE_CART' })}>
          ×
        </CartCloseButton>
      </CartHeader>
      <CartLineItems>{line_items}</CartLineItems>
      <CartFooter>
        <CartInfo>
          <CartInfoTotal>Subtotal</CartInfoTotal>
          <CartInfoPricing>
            <CartInfoPricingAmount>
              {formatPrice(state.checkout.subtotal, state.currency)}
            </CartInfoPricingAmount>
          </CartInfoPricing>
        </CartInfo>
        <Button
          appearance={state.checkout.items.length === 0 ? 'ghost' : 'cta'}
          size="medium"
          onClick={openCheckout}
          loadingText="Checking Out"
          loading={isSubmitting}
          isUnclickable={isSubmitting || state.checkout.items.length === 0}
        >
          Checkout
        </Button>
      </CartFooter>
    </CartContainer>
  );
};

Cart.propTypes = {};

export default Cart;
