import gql from 'graphql-tag';

export const getCurrencies = gql`
  query {
    shop {
      paymentSettings {
        enabledPresentmentCurrencies
      }
    }
  }
`;

export const GET_PRODUCT_BY_HANDLE = gql`
  query getProductByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      id
      tags
      options {
        name
        values
      }
    }
  }
`;

export const getProductsByTag = gql`
  query($productTag: String!) {
    products(first: 250, query: $productTag) {
      edges {
        node {
          id
          handle
          title
          availableForSale
          totalInventory
          descriptionHtml
          options {
            name
            values
          }
          variants(first: 20) {
            edges {
              node {
                id
                title
                availableForSale
                quantityAvailable
                currentlyNotInStock
                priceV2 {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                image {
                  placeholder: transformedSrc(
                    maxWidth: 100
                    preferredContentType: JPG
                  )
                }
                presentmentPrices(first: 250) {
                  edges {
                    node {
                      price {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
          images(first: 20) {
            edges {
              node {
                placeholder: transformedSrc(
                  maxWidth: 100
                  preferredContentType: JPG
                )
                small: transformedSrc(maxWidth: 750, preferredContentType: JPG)
                medium: transformedSrc(
                  maxWidth: 1500
                  preferredContentType: JPG
                )
                large: transformedSrc(maxWidth: 3000, preferredContentType: JPG)
                smallWebp: transformedSrc(
                  maxWidth: 750
                  preferredContentType: WEBP
                )
                mediumWebp: transformedSrc(
                  maxWidth: 1500
                  preferredContentType: WEBP
                )
                largeWebp: transformedSrc(
                  maxWidth: 3000
                  preferredContentType: WEBP
                )
                altText
              }
            }
          }
        }
      }
    }
  }
`;

export const getCollectionByHandle = gql`
  query($collection: String!) {
    collectionByHandle(handle: $collection) {
      products(first: 250) {
        edges {
          node {
            id
            handle
            title
            vendor
            presentmentPriceRanges(first: 20) {
              edges {
                node {
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
            images(first: 2) {
              edges {
                node {
                  card: transformedSrc(
                    maxWidth: 400, 
                    maxHeight: 600, 
                    scale: 2, 
                    crop: CENTER, 
                    preferredContentType: JPG
                  )
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`;
