import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import Analytics from 'react-router-ga'

import { useQuery } from '@apollo/react-hooks'

import { DropDown } from './components'
import {
  Cart,
  Collection,
  ProductPageQuery,
  Error,
  getCurrencies,
} from './pages'

import { store } from './store.js'

const trackingId = 'UA-87727037-10'

const AppContainer = styled.div`
  width: 100%;
`

function App() {
  const { state, dispatch } = useContext(store)

  //get the currencies defined and enabled in shopify
  const { loading, error, data } = useQuery(getCurrencies)

  // Get the data attributes from root element
  const rootElement = document.getElementById('kotn-supply-buy-button')

  if (!rootElement) {
    return (
      <Error message="Sorry, it seems that the required code snippet is missing (#kotn-supply-buy-button)." />
    )
  }

  const collection = rootElement.getAttribute('data-collection')
  const product = rootElement.getAttribute('data-product')
  const vendor = rootElement.getAttribute('data-vendor')

  if (loading) {
    return <p></p>
  }
  if (error) {
    console.error(error)
    return <Error />
  }

  if (!collection && !product) {
    return <Error message="The product handle is undefined." />
  }

  if (!vendor) {
    return <Error message="The vendor is undefined" />
  }

  function handleSelectedItemChange({ selectedItem }) {
    dispatch({ type: 'SET_CURRENCY', currency: selectedItem })
  }
  function NoMatch() {
    let location = useLocation()

    return <Error message={`no match for ${location.pathname}.`} />
  }
  return (
    <AppContainer>
      <Router>
        <Analytics id={trackingId}>
          <Switch>
            <Route exact path="/">
              {collection ? (
                <Collection collection={collection} vendor={vendor} />
              ) : (
                <Redirect to={'products/' + product} />
              )}
            </Route>
            <Route path="/products/:handle">
              {collection || product ? (
                <ProductPageQuery vendor={vendor} />
              ) : (
                <NoMatch />
              )}
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Analytics>
      </Router>
      <Cart />
      {data && (
        <DropDown
          items={data.shop.paymentSettings.enabledPresentmentCurrencies}
          selectedItem={state.currency}
          handleSelectedItemChange={handleSelectedItemChange}
        />
      )}
    </AppContainer>
  )
}

export default App
