import styled from 'styled-components';

import { typography } from '../../styles';

import H3 from '../../elements/H3';

const Title = styled(H3)`
  font-size: ${typography.fontSize.sm};
  margin: 0;
  font-weight: ${typography.fontWeight.normal};
  line-height: ${typography.lineHeight.tight};
`;

export default Title;
