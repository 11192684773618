import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../styles';

const ErrorPage = styled.div`
  color: #000;
  background: #fff;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    h1 {
      display: inline-block;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      margin: 0;
      margin-right: 20px;
      padding: 10px 23px 10px 0;
      font-size: 24px;
      font-weight: 500;
      vertical-align: top;
    }
    div {
      display: inline-block;
      text-align: left;
      line-height: 49px;
      height: 49px;
      vertical-align: middle;
      h2 {
        font-size: 14px;
        font-weight: normal;
        line-height: inherit;
        margin: 0;
        padding: 0;
        a {
          color: ${color.teal[900]};
          text-decoration: none;
          &:hover {
            color: ${color.teal[600]};
            border-bottom: 1px solid currentColor;

            line-height: 1.1;
          }
        }
      }
    }
  }
`;

const Error = ({ message = 'This page could not be found.' }) => {
  return (
    <ErrorPage>
      <div>
        <h1>404</h1>
        <div>
          <h2>
            {message}&nbsp;<a href="/">Back to Homepage</a>
          </h2>
        </div>
      </div>
    </ErrorPage>
  );
};

Error.propTypes = {
  /**
   * Error Message to display
   * @default 'This page could not be found.'
   */
  appearance: PropTypes.string,
};

export default Error;
