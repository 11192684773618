import styled from 'styled-components';
import { spacing } from '../../styles';

import Header from './Header';
import Title from './Title';
import Content from './Content';
import Body from './Body';

const Panel = styled.div`
  margin: auto;
  padding: ${spacing.padding['0']};
  background-color: transparent;
  border-radius: ${spacing.borderRadius.default};
  overflow: hidden;
`;

Panel.Body = Body;
Panel.Header = Header;
Panel.Title = Title;
Panel.Content = Content;

export default Panel;
