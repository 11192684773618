import styled from 'styled-components';
import {
  spacing,
  background,
  sizing,
  media,
  mediaLandscape,
} from '../../styles';

export const FormContainer = styled.form`
  max-width: ${sizing.maxWidth.full};
  display: grid;
  gap: 1rem 1rem;
  background-color: ${background.app};
  padding: 0 1rem;

  ${media.L`
    height: ${sizing.height.full};
    max-width: ${sizing.maxWidth.md};
    min-width: ${sizing.maxWidth.md};
    padding: ${spacing.padding[8]};
    align-self: center;
  `}

  ${mediaLandscape.L`
    height: ${sizing.height.full};
    max-width: ${sizing.maxWidth.xs};
    padding: ${spacing.padding[4]};
    align-self: center;
  `}
`;
